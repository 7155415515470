import React, { createContext, useContext, useReducer } from 'react';

type Action = {
	type: 'toast';
	payload: any;
};

type Dispatch = (action: Action) => void;

const GlobalContext = createContext<{state:any; dispatch:Dispatch} | undefined>(undefined);

const globalReducer = (state: any, action: Action) => {
	switch (action.type) {
		case 'toast': {
			return {
				open: action.payload?.open || false,
				severity: action.payload?.severity || state.severity || 'info',
				message: action.payload?.message || state.message || '',
			}
		}
		default: {
			throw new Error(`Unhandled action type: ${action.type}`)
		}
	}
};

const GlobalContextProvider = ({ children }: any) => {
	const [state, dispatch] = useReducer(globalReducer, { open: false, severity: 'info', message: '' });

	return (
		<GlobalContext.Provider value={{ state, dispatch }}>
			{children}
		</GlobalContext.Provider>
	)
};


const useGlobalContext = () => {
	const context = useContext(GlobalContext);

	if(context === undefined)
		throw new Error('useCount must be used within a CountProvider')
	
	return context;
};

export { useGlobalContext, GlobalContextProvider };
