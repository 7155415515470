import React from 'react';
import clsx from 'clsx';

// Helpers
import { LINKS } from 'helpers/constants';

// Components
import AppMenuItem from 'components/Layout/AppMenuItem';
import HeaderUser from './HeaderUser';

// Icons
import HomeIcon from '@material-ui/icons/Home';
import ProductIcon from 'icons/ProductIcon';
import AttributeIcon from '@material-ui/icons/Assignment';
import CatalogIcon from 'icons/CatalogIcon';
import CampaignIcon from 'icons/CampaignIcon';
import AccountIcon from '@material-ui/icons/AccountBox';
import CustomerIcon from '@material-ui/icons/PeopleAlt';
import SettingIcon from '@material-ui/icons/Settings';
import ThemesIcon from '@material-ui/icons/ColorLens';
import MediaLibraryIcon from '@material-ui/icons/PermMediaSharp';
import BroadcastIcon from '@material-ui/icons/SettingsInputAntenna';
import SiteComponentIcon from '@material-ui/icons/WebSharp';
import BookIcon from '@material-ui/icons/Book';
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted'; 
import DashboardIcon from '@material-ui/icons/Dashboard'; 
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import ScheduleIcon from '@material-ui/icons/Schedule'; 
import NotificationsIcon from '@material-ui/icons/Notifications';

// Material-UI
import { makeStyles, Theme } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListSubheader from '@material-ui/core/ListSubheader';
import Divider from '@material-ui/core/Divider';

interface Props {};

const AppMenu: React.FC<Props> = (props) => {
	const classes = useStyles();

	return (
		<div className={classes.root}>
			<div className={classes.main}>
				{/* <ButtonGroup className={classes.buttonGroup} color="primary" size="small" fullWidth aria-label="outlined primary button group">
					<Button variant="outlined" disableElevation data-selected={view === 1} onClick={() => setView(1)}>All</Button>
					<Button variant="outlined" disableElevation data-selected={view === 2} onClick={() => setView(2)}>Favorites</Button>
				</ButtonGroup> */}
				
				{/*<ListSubheader component="div" className={classes.subheader}>Main</ListSubheader>
				<List component="nav" className={clsx(classes.list, classes.iconList)}>
					<AppMenuItem name="Home" url={LINKS.HOME} icon={<HomeIcon/>}/>
					<AppMenuItem name="Products" url="/products" icon={<ProductIcon/>}/>
					<AppMenuItem name="Attributes" url="/attributes" icon={<AttributeIcon/>}/>
					<AppMenuItem name="Catalogs" url="/catalogs" icon={<CatalogIcon/>}/>
					<AppMenuItem name="Customers" url="/customers" icon={<CustomerIcon/>}/>
					<AppMenuItem name="Media Library" url="/media-library" icon={<MediaLibraryIcon/>}/>
				</List>

				<ListSubheader component="div" className={classes.subheader}>Campaign Management</ListSubheader>
				<List component="nav" className={`${classes.list} ${classes.iconList}`}>
					<AppMenuItem name="Campaigns" url="/campaigns" icon={<CampaignIcon/>}/>
					<AppMenuItem name="Broadcasts" url="/broadcasts" icon={<BroadcastIcon/>}/>
					<AppMenuItem name="Site Components" url="/site-components" icon={<SiteComponentIcon/>}/>
					<AppMenuItem name="Articles" url="/articles" icon={<BookIcon/>}/>
				</List>

				<ListSubheader component="div" className={classes.subheader}>Business Commerce</ListSubheader>
				<List component="nav" className={clsx(classes.list, classes.iconList)}>
					<AppMenuItem name="Accounts" url="/accounts" icon={<AccountIcon/>}/>
			</List> */}

				<ListSubheader component="div" className={classes.subheader}>Main</ListSubheader>
				<List component="nav" className={clsx(classes.list, classes.iconList)}>
					{/*<AppMenuItem name="Products" url={''} hash={''} icon={<ProductIcon/>}/> */}
					<AppMenuItem name="Dashboard" url={'/studio'} hash={'/process-records'} icon={<DashboardIcon />} />
					<AppMenuItem name="Notifications" url={'/studio'} hash={'/notifications'} icon={<NotificationsIcon />} />
					<AppMenuItem name="Log" url={'/studio'} hash={'/process-events/list/_/_'} icon={<FormatListBulletedIcon />} />
					{/*<AppMenuItem name="Schedules" url={'/studio'} hash={'/process-schedules'} icon={<ScheduleIcon/>}/> */}
					<AppMenuItem name="Processes" url={'/studio'} hash={'/processes'} icon={<ScheduleIcon />} />
					<AppMenuItem name="Magic Wand" url={'/magicwand'} icon={<AutoFixHighIcon  />} />
				</List>

				<ListSubheader component="div" className={classes.subheader}>Administration</ListSubheader>
				<List component="nav" className={clsx(classes.list, classes.iconList)}>
					<AppMenuItem name="Employees" url={'/settings/employees'} icon={<CustomerIcon />} />
					<AppMenuItem name="Settings" url={'/settings/GEN'} icon={<SettingIcon />} />			
				</List>
			</div>
			<Divider/>
			<div className={classes.bottom}>
				<HeaderUser/>
			</div>
		</div>
	);
};

const useStyles = makeStyles((theme: Theme) => ({
	root: {
		height: '100%',
		display: 'flex',
		flexDirection: 'column',
	},
	main: {
		flex: '1 0 0px',
		padding: theme.spacing(1),
	},
	bottom: {
		padding: theme.spacing(1),

		// backgroundColor: theme.palette.grey[200],
	},
	buttonGroup: {
		marginBottom: theme.spacing(1),
		'& > .MuiButton-root': {
			lineHeight: 1.5,
			fontSize: '1.1rem',
		},
		'& > .MuiButton-root[data-selected="true"]': {
			backgroundColor: theme.palette.primary.main,
			color: theme.palette.primary.contrastText,
		},
	},
	subheader: {
		lineHeight: 'initial',
		padding: theme.spacing(0.5),
		fontWeight: 500,
		fontSize: '1.2rem',
		textTransform: 'uppercase',
	},
	iconList: {
		padding: theme.spacing(0, 0, 2, 0),
		paddingLeft: `${theme.spacing(0)} !important`,
		'& > .MuiLink-root > .MuiListItem-root': {
			padding: `2px 2px 2px 8px !important`,
			'& > .MuiListItemText-root > .MuiTypography-displayBlock': {
				fontWeight: 500,
			},
		},
		'& > .MuiLink-root > .MuiCollapse-container > .MuiCollapse-wrapper > .MuiCollapse-wrapperInner > .MuiList-root': {
			paddingLeft: `${theme.spacing(4)}px !important`,
		},
	},
	list: {
		paddingLeft: theme.spacing(2.5),
	},
}));

export default AppMenu;
