import React, { Suspense } from "react";
import { Switch, Redirect } from "react-router-dom";

// Components
import Loading from "components/Layout/Loading";

// Routes
import RouteGuard from "routes/Route";
import ThemeBuilder from "../pages/themes";

const Login = React.lazy(() => import("pages/login"));
const NotFound = React.lazy(() => import("pages/not-found"));

// Apps
const Home = React.lazy(() => import("pages/home"));
const TestPage = React.lazy(() => import("pages/testPage"));
const Products = React.lazy(() => import("pages/products"));
const Attributes = React.lazy(() => import("pages/attributes"));
const Articles = React.lazy(() => import("pages/articles"));
const Studio = React.lazy(() => import("pages/studio"));
const Catalogs = React.lazy(() => import("pages/catalogs"));
const Customers = React.lazy(() => import("pages/customers"));
const Accounts = React.lazy(() => import("pages/accounts"));
const MediaLibrary = React.lazy(() => import("pages/media-library"));
const Campaigns = React.lazy(() => import("pages/campaigns"));
const Broadcasts = React.lazy(() => import("pages/broadcasts"));
const SiteComponents = React.lazy(() => import("pages/site-components"));
const Settings = React.lazy(() => import("pages/settings"));
const SettingsGeneral = React.lazy(() => import("pages/settings/general"));
const SettingsPricing = React.lazy(() => import("pages/settings/pricing"));
const SettingsProducts = React.lazy(() => import("pages/settings/products"));
const SettingsCustomers = React.lazy(() => import("pages/settings/customers"));
const SettingsOrders = React.lazy(() => import("pages/settings/orders"));
const SettingsPayments = React.lazy(() => import("pages/settings/payments"));
const SettingsCheckout = React.lazy(() => import("pages/settings/checkout"));
const SettingsEmployees = React.lazy(() => import("pages/settings/employees"));
const SettingsRolesAndPermissions = React.lazy(() => import("pages/settings/roles-permissions"));
const SettingsSystem = React.lazy(() => import("pages/settings/system"));
const SettingsSearchEngine = React.lazy(() => import("pages/settings/search-engine"));
const SettingsWebsites = React.lazy(() => import("pages/settings/websites"));
const SettingsListOfValues = React.lazy(() => import("pages/settings/list-of-values"));
const Profile = React.lazy(() => import('pages/profile'));
const ResetPassword = React.lazy(() => import('pages/reset-password'));
const ForgotPassword = React.lazy(() => import('pages/forgot-password'));
const MagicWand = React.lazy(() => import('pages/magicwand'));

export default function Routes() {
  return (
    <Suspense fallback={<Loading fullscreen />}>
      <Switch>
        <RouteGuard
          path="/"
          layout="default"
          component={Home}
          exact
          isPrivate={true}
        />
        <RouteGuard
          path="/testPage"
          layout="default"
          component={TestPage}
          exact
          isPrivate={true}
        />
        <RouteGuard
          path="/products"
          layout="default"
          component={Products}
          isPrivate={true}
        />
        <RouteGuard
          path="/notifications"
          layout="default"
          component={Notification}
          isPrivate={true}
        />
        <RouteGuard
          path="/attributes"
          layout="default"
          component={Attributes}
          isPrivate={true}
        />
        <RouteGuard
          path="/articles"
          layout="default"
          component={Articles}
          isPrivate={true}
        />
        <RouteGuard
          path="/studio"
          layout="default"
          component={Studio}
          isPrivate={true}
        />
        <RouteGuard
          path="/catalogs"
          layout="default"
          component={Catalogs}
          isPrivate={true}
        />
        <RouteGuard
          path="/customers"
          layout="default"
          component={Customers}
          isPrivate={true}
        />
        <RouteGuard
          path="/magicwand"
          layout="default"
          component={MagicWand}
          isPrivate={true}
        />
        <RouteGuard
          path="/accounts"
          layout="default"
          component={Accounts}
          isPrivate={true}
        />
        <RouteGuard
          path="/media-library"
          layout="default"
          component={MediaLibrary}
          isPrivate={true}
        />
        <RouteGuard
          path="/themes"
          layout="default"
          component={ThemeBuilder}
          exact
          isPrivate={true}
        />
        <RouteGuard
          path="/campaigns"
          layout="default"
          component={Campaigns}
          isPrivate={true}
        />
        <RouteGuard
          path="/broadcasts"
          layout="default"
          component={Broadcasts}
          isPrivate={true}
        />
        <RouteGuard
          path="/site-components"
          layout="default"
          component={SiteComponents}
          isPrivate={true}
        />
        <RouteGuard
          path="/settings"
          layout="default"
          component={Settings}
          exact
          isPrivate={true}
        />
        <RouteGuard
          path="/settings/GEN"
          layout="default"
          component={SettingsGeneral}
          exact
          isPrivate={true}
        />
        <RouteGuard
          path="/settings/PROD"
          layout="default"
          component={SettingsProducts}
          exact
          isPrivate={true}
        />
        <RouteGuard
          path="/settings/CUST"
          layout="default"
          component={SettingsCustomers}
          exact
          isPrivate={true}
        />
        <RouteGuard
          path="/settings/ORD"
          layout="default"
          component={SettingsOrders}
          exact
          isPrivate={true}
        />
        <RouteGuard
          path="/settings/PAY"
          layout="default"
          component={SettingsPayments}
          exact
          isPrivate={true}
        />
        <RouteGuard
          path="/settings/CHECK"
          layout="default"
          component={SettingsCheckout}
          exact
          isPrivate={true}
        />
        <RouteGuard
          path="/settings/roles-permissions"
          layout="default"
          component={SettingsRolesAndPermissions}
          isPrivate={true}
        />
        <RouteGuard
          path="/settings/search-engine"
          layout="default"
          component={SettingsSearchEngine}
          isPrivate={true}
        />
        <RouteGuard
          path="/settings/price"
          layout="default"
          component={SettingsPricing}
          isPrivate={true}
        />

        <RouteGuard
          path="/settings/employees"
          layout="default"
          component={SettingsEmployees}
          isPrivate={true}
        />

        <RouteGuard
          path="/settings/system"
          layout="default"
          component={SettingsSystem}
          isPrivate={true}
        />

        <RouteGuard
          path="/settings/websites/:webId?"
          layout="default"
          component={SettingsWebsites}
          isPrivate={true}
        />

        <RouteGuard
          path="/settings/list-of-values/:listId?/:valueId?"
          layout="default"
          component={SettingsListOfValues}
          isPrivate={true}
        />

        <RouteGuard
					path="/profile"
					layout="default"
					component={Profile}
					isPrivate={true}
				/>

        <RouteGuard path="/reset-password" component={ResetPassword} />
        <RouteGuard path="/forgot-password" layout="auth" component={ForgotPassword} />
        <RouteGuard path="/login" layout="auth" component={Login} />
        <RouteGuard
          path="/404"
          component={NotFound}
          isPrivate={false}
        ></RouteGuard>
        <Redirect to="/404" />
      </Switch>
    </Suspense>
  );
}
